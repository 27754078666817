<template>
  <div>
    <v-card
      v-for="(parent, i) in parents"
      :key="i"
      height="200px"
      class="mx-16 my-8 d-flex flex-column justify-space-between"
      style=" border: 2px dashed #f8c125;
              border-radius: 16px;"
    >
      <section class="pa-6">
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col>
                <span class="mr-2 font-weight-bold h3" v-text="parent.firstName" /><span class="font-weight-light h3" v-text="parent.lastName" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5" class="d-flex align-end">
                <span class="grey--text text-caption" v-text="$t('project.academy.users.parentLabel')" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-avatar size="64" style=" border: 2px solid #f8c125;">
              <img v-if="parent.avatar" alt="Avatar" :src="parent.avatar">
              <v-icon v-else color="primary" v-text="'person'" />
            </v-avatar>
          </v-col>
        </v-row>
      </section>
      <v-card-text style="background-color: #fef9e9;">
        <v-row class="d-flex pl-2">
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '3' : '2'" class="d-flex flex-row">
            <v-icon color="primary" small>
              cake
            </v-icon>
            <span class="ml-2" v-text="helpers.formatBirthdate(parent)" />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '3' : '2'" class="d-flex flex-row">
            <v-icon color="primary" small>
              outlined_flag
            </v-icon>
            <span class="ml-2" v-text="helpers.formatCountry(parent)" />
          </v-col>
          <v-col class="d-flex flex-row">
            <v-icon color="primary" small>
              email
            </v-icon>
            <span class="ml-2" v-text="parent.email" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PlayerCard',
  props: {
    parents: {
      type: Array,
      required: true,
    },
    helpers: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.v-avatar img {
  width: 100%;
  object-fit: cover;
}
</style>
